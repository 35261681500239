<template>
  <div class="page-main">
    <SaleTabs />
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" export print custom :loading="loading">
      <template #buttons>
        <el-button type="success" size="mini" @click="itemDeliveryAdd">开送货单</el-button>
        <el-button type="warning" size="mini" @click="itemSk">开收款单</el-button>
        <el-button type="warning" size="mini" @click="itemBx">关联报销</el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      show-footer
      highlight-current-row
      :loading="loading"
      :columns="tableColumn"
      :data="tableData"
      :max-height="this.$util.getViewHeight() - 150"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :keyboard-config="{ isArrow: true }"
      :footer-method="footerMethod"
      @cell-dblclick="itemEdit"
      @keydown="tableKeydown"
      @current-change="tableCurrentChange"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.bill_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_bill_code="{ row }">
        <el-link type="primary" @click="itemEdit"> {{ row.bill_code }} </el-link>
      </template>

      <template #default_shou_kuan_ji_lu="{ row }">
        <el-link type="primary" @click="itemRecord(row)">收款记录</el-link>
      </template>
      <template #default_out_bill_code_list="{ row }">
        <div v-for="(item, index) in row.out_bill_code_list" :key="index">
          <el-link type="primary" @click="itemDeliveryEdit(item)">{{ item }}</el-link>
        </div>
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 开送货单 -->
    <PopupShAdd
      v-if="isShowPopupShAdd"
      :show.sync="isShowPopupShAdd"
      :billId="activeBillId"
      @success="searchListData"
    />

    <!-- 编辑送货单 -->
    <PopupShEdit
      v-if="isShowPopupShEdit"
      :show.sync="isShowPopupShEdit"
      :code="activeDeliveryCode"
      @success="searchListData"
    />

    <!-- 开收款单 -->
    <PopupSk
      v-if="isShowPopupSk"
      :show.sync="isShowPopupSk"
      :billId="activeBillId"
      @success="searchListData"
    />

    <!-- 关联报销 -->
    <PopupBx
      v-if="isShowPopupBx"
      :show.sync="isShowPopupBx"
      :billId="activeBillId"
      @success="searchListData"
    />

    <!-- 收款记录 -->
    <PopupRecord v-if="isShowPopupRecord" :show.sync="isShowPopupRecord" :detailId="activeBillId" />
  </div>
</template>

<script>
import SaleTabs from '../../common/sale-tabs'
import PopupShAdd from '../../../common/popup-sh-add'
import PopupShEdit from '../../../common/popup-sh-edit'
import PopupSk from '../../../common/popup-sk'
import PopupBx from '../../../common/popup-bx'
import PopupRecord from './popup-record'
export default {
  components: {
    SaleTabs,
    PopupShAdd,
    PopupShEdit,
    PopupSk,
    PopupBx,
    PopupRecord
  },
  data() {
    return {
      loading: true,

      // filter
      filterData: {
        bill_date: [this.$util.getDate().monthDay, this.$util.getDate().today],
        is_delete: false,
        status: 2 // 审核通过
      },
      sortData: {
        bill_date: 'desc'
      },

      // table
      headerFilterUrl: '/admin/gcvip/checkBill/searchHeard',
      tableData: [],
      tableColumn: [
        {
          fixed: 'left',
          type: 'radio',
          width: 50
        },
        {
          title: 'ID',
          field: 'bill_id',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '销售日期',
          field: 'bill_date',
          width: 180,
          slots: { header: 'header_daterange' }
        },
        {
          title: '销售单号',
          field: 'bill_code',
          slots: { header: 'header_autocomplete', default: 'default_bill_code' }
        },
        {
          title: '订单金额(元)',
          field: 'sales_amount_total',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '客户名称',
          field: 'company_name',
          width: 240,
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '业务员',
          field: 'saler_name',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '收款记录',
          slots: { default: 'default_shou_kuan_ji_lu' }
        },
        {
          title: '送货单号',
          field: 'out_bill_code_list',
          slots: { header: 'header_autocomplete', default: 'default_out_bill_code_list' }
        },
        {
          title: '送货单金额(元)',
          field: 'out_bill_money',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '已收金额(元)',
          field: 'amount_received',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '送货未收金额',
          field: 'un_amount_received',
          slots: { header: 'header_autocomplete' }
        },
        {
          title: '备注',
          width: 240,
          field: 'bill_remark',
          slots: { header: 'header_autocomplete' }
        }
      ],
      pageOptions: {
        ...this.$constant.page,
        pageSize: 500
      },
      totalObj: {
        sales_amount_total_all: '',
        out_bill_money_all: '',
        amount_received_all: '',
        un_amount_received_all: ''
      },

      activeBillId: null,
      activeDeliveryCode: null,
      isShowPopupShAdd: false,
      isShowPopupShEdit: false,
      isShowPopupSk: false,
      isShowPopupBx: false,
      isShowPopupRecord: false
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/checkBill/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: this.filterData,
          sortData: this.sortData
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let data = res.data.page
            this.tableData = data.list
            this.pageOptions.pageSize = data.pageSize
            this.pageOptions.total = data.totalRow
            this.pageOptions.currentPage = data.pageNumber
            this.totalObj.sales_amount_total_all = res.data.sales_amount_total_all
            this.totalObj.out_bill_money_all = res.data.out_bill_money_all
            this.totalObj.amount_received_all = res.data.amount_received_all
            this.totalObj.un_amount_received_all = res.data.un_amount_received_all
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (column.field === 'sales_amount_total') return this.totalObj.sales_amount_total_all
          if (column.field === 'out_bill_money') return this.totalObj.out_bill_money_all
          if (column.field === 'amount_received') return this.totalObj.amount_received_all
          if (column.field === 'un_amount_received') return this.totalObj.un_amount_received_all
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 获取-当前单选行
    getRadioRecord() {
      return this.$refs.refTable.getRadioRecord()
    },
    // 表格-高亮行更改
    tableCurrentChange(params) {
      this.$refs.refTable.setRadioRow(params.row) // 选中radio
    },
    // 表格-键盘空格
    tableKeydown(params) {
      if (params.$event.code === 'Space') {
        let row = this.$refs.refTable.getCurrentRecord()
        let isCurrentRowRadio = this.$refs.refTable.isCheckedByRadioRow(row)
        if (isCurrentRowRadio) {
          this.$refs.refTable.clearRadioRow() // 清空radio
        } else {
          this.$refs.refTable.setRadioRow(row) // 选中radio
        }
      }
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 项-编辑
    itemEdit() {
      setTimeout(() => {
        let row = this.getRadioRecord()
        if (row) {
          this.itemEditAction(row.id)
        } else {
          this.itemChoosePrompt()
        }
      }, 100)
    },
    // 项-编辑-执行
    itemEditAction(id) {
      this.$router.push(`/sale/order/old-detail?id=${id}`)
    },
    // 项-开送货单
    itemDeliveryAdd() {
      let row = this.getRadioRecord()
      if (row) {
        this.activeBillId = row.bill_id
        this.isShowPopupShAdd = true
      } else {
        this.activeBillId = null
        this.itemChoosePrompt()
      }
    },
    // 项-编辑送货单
    itemDeliveryEdit(code) {
      this.activeDeliveryCode = code
      this.isShowPopupShEdit = true
    },
    // 项-开收款单
    itemSk() {
      let row = this.getRadioRecord()
      if (row) {
        this.activeBillId = row.bill_id
        this.isShowPopupSk = true
      } else {
        this.activeBillId = null
        this.itemChoosePrompt()
      }
    },
    // 项-关联报销
    itemBx() {
      let row = this.getRadioRecord()
      if (row) {
        this.activeBillId = row.bill_id
        this.isShowPopupBx = true
      } else {
        this.activeBillId = null
        this.itemChoosePrompt()
      }
    },
    // 项-收款记录
    itemRecord(row) {
      this.activeBillId = row.bill_id
      this.isShowPopupRecord = true
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
